import React, { useState, useEffect, useRef } from "react";
import Lofi from "./Lofi";
function Timer() {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(25);
  const [isActive, setIsActive] = useState(false);
  const [isWorkMode, setIsWorkMode] = useState(true);
  const [isModalActive, setIsModalActive] = useState(false);
  const [mode, setMode] = useState("regular");
  const [custom, setCustom] = useState(["23", "5"]);

  useEffect(() => {
    switch (mode) {
      case "regular":
        setMinutes(25);
        setSeconds(0);
        break;
      case "fast":
        setMinutes(10);
        setSeconds(0);
        break;
      case "custom":
        setMinutes(custom[0]);
        setSeconds(0);
      default:
        break;
    }
  }, [mode]);
  // Count downTimer
  useEffect(() => {
    const timer = setInterval(() => {
      if (isActive) {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (minutes === 0 && seconds === 0) {
          clearInterval(timer);
          switch (mode) {
            case "regular":
              if (isWorkMode) {
                setMinutes(5);
                setSeconds(0);
                setIsWorkMode(false);
                timer();
              } else {
                setMinutes(25);
                setSeconds(0);
                setIsWorkMode(true);
                timer();
              }
              break;
            case "fast":
              if (isWorkMode) {
                setMinutes(3);
                setSeconds(0);
                setIsWorkMode(false);
                timer();
              } else {
                setMinutes(10);
                setSeconds(0);
                setIsWorkMode(true);
                timer();
              }
              break;
            case "custom":
              if (isWorkMode) {
                setMinutes(custom[0]);
                setSeconds(0);
                setIsWorkMode(false);
                timer();
              } else {
                setMinutes(custom[1]);
                setSeconds(0);
                setIsWorkMode(true);
                timer();
              }
              break;
            default:
              break;
          }
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds, isActive, isWorkMode, minutes]);

  // Falling emojis

  function toggleModal() {
    setIsModalActive(!isModalActive);
  }

  return (
    <>
      {isModalActive && (
        <>
          <Modal
            toggleModal={toggleModal}
            setCustom={setCustom}
            setMode={setMode}
            setIsWorkMode={setIsWorkMode}
          />
        </>
      )}
      <div className={!isWorkMode ? "containerRest" : "containerWork"}>
        <div className="clock flex w-screen justify-center text-9xl pt-20 z-10 pb-100">
          <h1 className="minutes tracking-wide">{minutes}</h1>
          <h1 className="colon tracking-wide">:</h1>
          <h1 className="seconds tracking-wide">
            {seconds < 10 ? "0" + seconds : seconds}
          </h1>
        </div>
        <div className="">
          <Lofi />
        </div>
        <button
          onClick={toggleModal}
          className="py-3 px-20 bg-none rounded-2xl border-2 border-white text-white text-5xl m-20 shadow-2xl font-bold tracking-widest h-min"
        >
          Mode
        </button>
        <button
          onClick={() => setIsActive(!isActive)}
          className="py-3 px-20 bg-none rounded-2xl border-2 border-white text-white text-5xl m-20 shadow-2xl font-bold tracking-widest h-min"
        >
          {!isActive ? "start" : "pause"}
        </button>
      </div>
    </>
  );
}

function Modal({ toggleModal, setCustom, setMode, setIsWorkMode }) {
  const workRef = useRef();
  const breakRef = useRef();
  function submit() {
    if (workRef.current.value !== "" && breakRef.current.value !== "") {
      setCustom([workRef.current.value, breakRef.current.value]);
      toggleModal();
      setMode("custom");
      setIsWorkMode(true);
    } else {
      alert("Please enter custom values");
    }
  }
  function Fast() {
    toggleModal();
    setIsWorkMode(true);
    setMode("fast");
  }
  function Regular() {
    toggleModal();
    setIsWorkMode(true);
    setMode("regular");
  }
  return (
    <>
      <div className="flex justify-center items-center z-10 absolute h-screen w-screen">
        <div className="flex flex-col w-2/3 h-2/3 bg-white rounded-xl  p-5 z-30">
          <div className="flex md:flex-row flex-col justify-between h-full p-10">
            <button
              onClick={() => Fast()}
              className="bg-red-300 h-full w-full text-center rounded-xl text-white text-3xl"
            >
              Fast
            </button>
            <button
              className="w-full text-center bg-blue-300 rounded-xl text-white text-3xl h-full"
              onClick={() => Regular()}
            >
              Regular
            </button>
            <div className="w-full flex flex-col justify-center items-center">
              <h1 className="text-3xl">Custom</h1>
              <label htmlFor="work">Work:</label>
              <input
                type="text"
                placeholder="23"
                className="work bg-slate-600 text-white placeholder:text-white rounded-xl text-center px-auto w-20"
                ref={workRef}
              />
              <label htmlFor="break">Break:</label>
              <input
                ref={breakRef}
                type="text"
                placeholder="5"
                className="break bg-slate-600 text-center text-white placeholder:text-white rounded-xl px-auto w-20"
              />
              <button onClick={() => submit()}>Start</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Timer;
